.nav {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 2em 0;
    border-bottom: 1px solid black;
}

.nav > * {
    margin-left: 2em;
}