.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-family: 'Roboto';
    font-weight: 600;
    background-color: white;
    border: none;
    border-radius: 999px;
    padding: .5em;
    color: hsl(243, 75%, 59%);
    transition: 0.1s;
    cursor: pointer;
}

.button > :nth-child(2) {
    margin-left: .5em;
}