main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.right {
    width: 300px;
    background-color: white;
    border-left: 1px solid black;
    height: 100%;
}